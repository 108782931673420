<template>
	<div class="pageheader" v-if="show">
		<div class="h_top">
			<div class="login_box">
				<div class="avatar" v-if="isauth>0">
					<img :src="user.avatar" />
					<span @click="loginout()">{{ $t("common.login_out") }}</span>
				</div>
				<div v-else @click="$router.push({ path: '/Login' });" style="cursor: pointer;">{{ $t("common.login") }}</div>
			</div>
			<div class="flag_box"><img :src="langflag" style="width: 22px;height: 22px;border-radius: 11px;" /></div>
			<div class="lang_box" @click='playgame=true'>
				<span>{{ lang }}</span>
				<van-icon name="arrow-down" color="#ffffff" size="12" />
			</div>
		</div>
		<div class="nav_box">
			<div class="logo"><img src="../pages/images/logo.png" /></div>
			<div class="navlist">
				<ul>
					<li @click="$router.push({ path: '/Home' });" :class="active==0?'on':''">{{ $t("common.navlist_item1") }}</li>
					<li @click="$router.push({ path: '/intro' });" :class="active==1?'on':''">{{ $t("common.navlist_item2") }}</li>
					<li @click="$router.push({ path: '/daifa' });" :class="active==2?'on':''">{{ $t("common.navlist_item3") }}</li>
					<li @click="$router.push({ path: '/news' });" :class="active==3?'on':''">{{ $t("common.navlist_item4") }}</li>
					<li @click="$router.push({ path: '/about' });" :class="active==4?'on':''">{{ $t("common.navlist_item5") }}</li>
					<li @click="$router.push({ path: '/product' });" :class="active==5?'on':''">{{ $t("common.navlist_item6") }}</li>
					<li @click="$router.push({ path: '/Mine' });" :class="active==6?'on':''">{{ $t("common.navlist_item7") }}</li>
				</ul>
			</div>
		</div>
		<div class="kefubox" @click="jumpkf()">
			<img src="../pages/images/user/kf2.png" />
		</div>
		<van-popup class="mask" get-container="body" v-model="playgame" >
			<div class="play-type-tip">
				<div class="title">{{ $t("common.lang") }}</div>
				<ul>
					<li @click="changeLang('en_us','English','en')">English</li>
					<li @click="changeLang('zh_cn','中文','cn')">中文</li>
					<li @click="changeLang('zh_hk','繁体中文','hk')">繁体中文</li>
				</ul>
			</div>
		</van-popup>
	</div>
</template>

<script>
import { Toast } from "vant";
export default {
	data() {
		return {
			lang:'中文',
			flagname:'cn',
			isauth:0,
			show: false,
			active: 0,
			user:{
				name:'',
				phone:'',
				email:'',
				avatar:''
			},
			playgame:false
		};
	},
	methods: {
		jumpkf(){
			window.open(this.$store.getters.getBaseInfo.yunkefu);
		},
		loginout() {
			localStorage.clear()
			this.$router.push({ path: '/Home' });
		},
		changeLang(lang,name,flagname) {
		
			Toast.loading({
				// mask: true,
				duration: 200,
			});
			this.lang = name;
			this.langflag = require('../pages/images/flags/'+flagname+'.png');
			this.$i18n.locale = lang;
			localStorage.setItem("lang", lang);
			localStorage.setItem("langname", name);
			localStorage.setItem("langflag", flagname);
			this.playgame=false;
			this.$router.push({path:'Home'});
		},
	},
	watch: {
		$route(to) {
			if (to.name == "Home") {
				this.active = 0;
				this.show = true;
			} else if (to.name == "Intro") {
				this.active = 1;
				this.show = true;
			} else if (to.name == "Daifa") {
				this.active = 2;
				this.show = true;
			} else if (to.name == "News") {
				this.active = 3;
				this.show = true;
			} else if (to.name == "About") {
				this.active = 4;
				this.show = true;
			} else if (to.name == "Product") {
				this.active = 5;
				this.show = true;
			} else if (to.name == "Mine" || to.name == "Share") {
				this.active = 6;
				this.show = true;
			} else {
				this.active = 7;
				this.show = false;
			}
		},
	},
	created() {
		this.langflag = require('../pages/images/flags/cn.png');
		if(localStorage.getItem("langname")){
			this.lang = localStorage.getItem("langname");
			this.langflag = require('../pages/images/flags/'+localStorage.getItem("langflag")+'.png');
		}
		if (this.$route.name == "Home") {
			this.active = 0;
			this.show = true;
		} else if (this.$route.name == "Intro") {
			this.active = 1;
			this.show = true;
		} else if (this.$route.name == "Daifa") {
			this.active = 2;
			this.show = true;
		} else if (this.$route.name == "News") {
			this.active = 3;
			this.show = true;
		} else if (this.$route.name == "About") {
			this.active = 4;
			this.show = true;
		} else if (this.$route.name == "Product") {
			this.active = 5;
			this.show = true;
		} else if (this.$route.name == "Mine" || this.$route.name == "Share" || this.$route.name == "RechargeRecord") {
			this.active = 6;
			this.show = true;
		} else {
			this.active = 7;
			this.show = false;
		}
		if(localStorage.getItem("sellerid")){
			this.isauth = localStorage.getItem("sellerid");
			this.user.email = localStorage.getItem("selleremail");
			this.user.phone = localStorage.getItem("sellerphone");
			this.user.avatar = localStorage.getItem("selleravatar");
			this.user.name = localStorage.getItem("sellername");
		}
	},
};
</script>

<style lang="less" scoped>
.pageheader{
	background: #000102;
	.h_top{
		max-width: 1200px;
		height: 53px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		position: relative;
		overflow: unset;
		.login_box{
			font-size: 14px;
			line-height: 53px;
			color: #fff;
			padding:0 15px;
			.avatar{
				display: flex;
				justify-content: center;
				align-items: center;
				img{
					width:30px;
					height: 30px;
					border-radius: 15px;
					margin-right: 10px;
				}
				span{
					cursor: pointer;
				}
			}
		}
		.flag_box{
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.lang_box{
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #fff !important;
			box-shadow: unset;
			border: none;
			padding-left: 20px;
			width: 100px;
		}
	}
	.nav_box{
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		padding: 10px 0;
		.logo{
			img{
				width: 200px;
			}
		}
		.navlist{
			ul{
				display: flex;
				li{
					line-height: 58px;
					padding: 0 20px;
					color: #fff;
					cursor: pointer;
				}
				li.on{
					border-bottom: 2px solid #ce2722;
				}
			}
		}
	}
}

.play-type-tip {
	position: unset;
	margin: auto;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 400px;
	max-height: 50%;
	z-index: 10;
	border-radius: 10px;
	overflow: hidden;
	background-color: #fff;
	color: #000;
}

.play-type-tip .title {
	line-height: 40px;
	background: #000;
	text-align: center;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
}

.mask {
	background-color: rgb(0 0 0 / 0%);
	animation-duration: 0.35s;
}

.play-type-tip li {
	padding: 10px;
	cursor: pointer;
}
.kefubox{
	position: fixed;
	top: 30%;
	right: 0;
	background: #409eff;
	padding: 3px 6px 3px 8px;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	img{
		width: 25px;
		height: 25px;
	}
}
</style>
