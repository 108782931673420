<template>
	<div class="minepage">
		<div class="banner">
			<img src="../images/banner/banner_06.jpg" style="width: 100%;" />
		</div>
		<div class="content_box">
			<div class="usheader">
				<div class="avatar"><img :src="userInfo.avatar" /></div>
				<div class="user-mate">
					<div class="money">{{ $t('my.balance') }}：{{ userInfo.money }}</div>
					<div class="btn" @click="$router.push({ path: '/Share' })" style="cursor: pointer;">{{ $t('my.share') }}</div>
				</div>
				<div class="btn-g">
					<div class="btn">{{ $t('my.recharge') }}</div>
					<div class="btn" @click="$router.push({ path: '/RechargeRecord' })">{{ $t('my.recharge_record') }}</div>
					<div class="btnout" @click="login_out()">{{ $t('my.logout') }}</div>
				</div>
			</div>
			
			<div class="sharebox">
				<div class="title">{{ $t('recharge.patit') }}</div>
				
				<div class="pagebox">
					<van-pagination v-model="page" :total-items="totalnum" :show-page-size="5" prev-text="&lt;" next-text="&gt;" force-ellipses @change="getProductList" />
				</div>
				<div class="datalist" v-if="tabind==0">
					<div class="listtit">
						<div>{{ $t('recharge.itemtit11') }}</div>
						<div>{{ $t('recharge.itemtit12') }}</div>
						<div>{{ $t('recharge.itemtit13') }}</div>
						<div>{{ $t('recharge.itemtit14') }}</div>
					</div>
					<div class="list">
						<div class="item" v-for="(item,ind) in recharge" :key="ind">
							<div>{{ item.payment_details }}</div>
							<div>{{ item.amount }}</div>
							<div>{{ $t('recharge.ztsta'+item.approval) }}</div>
							<div>{{ item.created_at }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			userInfo:{
				name:'',
				phone:'',
				email:'',
				avatar:'',
				money:0.00
			},
			uid:0,
			menu_top: 40,
			isLoading: false,
			recharge:[],
			pagelist:[],
			active_num:false,
			pronum:'全部',
			isChecked:false,
			de_status:'',
			order_popup:false,
			orderdata:{
				code:'',
				created_at:'',
				product_storehouse_total:'',
				lirun:'',
				address:{
					name:'',
					email:'',
					phone:'',
					country:'',
					city:'',
					state:'',
					address:'',
				},
				detail:{

				}
			},
			page:1,
			totalnum:0,
			tabind:0,
			spreadurl:''
		};
	},
	computed:{
		allcheck:{
			get(){
				//取值
				//every方法，数组中每一项都满足一个条件返回true
				return this.prolist.length && this.prolist.every(item=>item.isChk)
			},
			set(newValue){
				//设置值
				this.prolist.map(item=>item.isChk=newValue)
			}
		},
	},
	methods: {
		login_out() {
			localStorage.clear()
			this.$router.push({ path: '/Home' });
		},
		seletab(num){
			this.tabind = num;
		},
		selpronum(item){
			if(item==0){
				this.pronum = '全部';
				this.de_status='';
			}
			else{
				this.pronum = this.$t("my."+item);
				this.de_status=item;
			}
			this.getOrder();
			this.active_num = false;
		},
		selectpro(){
			let ids='0';
			this.prolist.forEach(item_p => {
				if(item_p.isChk){
					ids += ',' + item_p.id;
				}
			});
			this.$http({
				method: 'get',
				url: 'orderfahuo',
				data:{
					ids:ids
				}
			}).then(res => {
				console.log(res);
				this.getOrder();
			})
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					this.userInfo = res.data;
					if(res.data.logo){
						localStorage.setItem("selleravatar", res.data.logo);
						this.userInfo.avatar = res.data.logo;
					}
					this.userInfo.money = res.data.money;
				}
			})
		},
		getRecharge(){
			this.$http({
				method: 'get',
				url: 'getrecharge'
			}).then(res => {
				if(res.code==1){
					this.recharge = res.data;
				}
			})
		}
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.userInfo.email = localStorage.getItem("selleremail");
			this.userInfo.phone = localStorage.getItem("sellerphone");
			this.userInfo.avatar = localStorage.getItem("selleravatar");
			this.userInfo.name = localStorage.getItem("sellername");
			this.getUserInfo();
			this.getRecharge();
		} else {
			this.$router.push({path:'/Login'})
		}
	}
};
</script>

<style lang='less' scoped>
.content_box {
	width: 100%;
	max-width: 1200px;
	margin: 0px auto 0;
	padding-top: 30px;
	position: relative;
	.usheader{
		background-color: #000102;
		padding: 50px 0;
		margin-top: 100px;
		border-radius: 10px;
			justify-content: space-between;
		display: flex;
		position: relative;
		.avatar{
			position: absolute;
			top: -80px;
			width: 150px;
			height: 150px;
			border-radius: 50%;
			overflow: hidden;
			margin-left: 60px;
			img{
				width: 100%;
				height: 100%;
				border-radius: 75px;
			}
		}
		.user-mate{
			flex: 3;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			color: #fff;
			margin-left: 20%;
			font-size: 18px;
			.btn{
				background: #f5af02;
				padding: 5px 10px;
				border-radius: 6px;
				margin-left: 20px;
			}
		}
		.btn-g{
			display: flex;
			.btn{
				padding: 5px 15px;
				font-size: 16px;
				background: #c9151e;
				color: #fff;
				margin-right: 20px;
				border-radius: 4px;
				cursor: pointer;
			}
			.btnout{
				padding: 5px 15px;
				font-size: 16px;
				background: #4b8538;
				color: #fff;
				margin-right: 20px;
				border-radius: 4px;
				cursor: pointer;
			}
		}
	}
}
.pagebox{
	display: flex;
	//padding-top: 10px;
	justify-content: end;
		width:960px;
		margin: 20px auto 0;
}
::v-deep .van-pagination__item{
	color: #000;
	height: 26px;
	min-width: 26px;
}
::v-deep .van-pagination__item--active{
	background: #c9151e;
	color: #fff;
}
.sharebox{
	background: #e5e5e5;
	margin-top: 20px;
	border-radius: 10px;
	padding-top: 50px;
	padding-bottom: 20px;
	.title{
		font-size: 30px;
		color: #000;
		position: relative;
		text-align: center;
		font-weight: 700;
	}
	.title:after{
		height: 5px;
		width: 100px;
		background-color: #982123;
		content: "";
		position: absolute;
		bottom: -10px;
		left: 50%;
		margin-left: -50px;
	}
	.money{
		padding: 10px 0;
		width:960px;
		margin: 20px auto 0;
		display: flex;
		justify-content: space-between;
		border-bottom: solid 1px #333;
		.str{
			padding-left: 10px;
			span{
				color: #982123;
			}
		}
		.btn{
			padding: 6px 20px;
			background: #853737;
			color: #fff;
			border-radius: 6px;
		}
	}
	.tabsbox{
		padding: 10px 0;
		width:960px;
		margin: 20px auto 0;
		display: flex;
		justify-content: space-between;
		.tabitem{
			display: flex;
			justify-content: flex-start;
			div{
				border-radius: 5px;
				margin-right: 15px;
				padding: 7px 10px;
				font-size: 15px;
				background-color: #c7c7c7;
				color: #000;
				cursor: pointer;
			}
			div.sel{
				background-color: #000;
				color: #fff;
			}
		}
		.ssbox{
			display: flex;
			justify-content: flex-end;
		}
	}
}
::v-deep .van-cell{
	color: #000;
	height: 30px;
	margin-left: 2px;
	padding:5px 8px;
}
.datalist{
	width:960px;
	margin: 20px auto 0;
	.listtit{
		display: flex;
		justify-content: space-around;
		width: 100%;
		div{
			width: 50%;
			background: #000;
			color: #fff;
			line-height: 40px;
			text-align: center;
			font-size: 16px;
			border: solid 1px #979797;
		}
	}
	.list{
		.item{
			display: flex;
			justify-content: space-around;
			width: 100%;
			div{
				width: 50%;
				line-height: 40px;
				text-align: center;
				font-size: 16px;
				border: solid 1px #979797;
			}
		}
	}
}
</style>
