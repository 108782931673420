<template>
	<div class="minepage">
		<div class="banner">
			<img src="../images/banner/banner_06.jpg" style="width: 100%;" />
		</div>
		<div class="content_box">
			<div class="usheader">
				<div class="avatar"><img :src="userInfo.avatar" /></div>
				<div class="user-mate">
					<div class="money">{{ $t('my.balance') }}：{{ userInfo.money }}</div>
					<div class="btn" @click="$router.push({ path: '/Share' })" style="cursor: pointer;">{{ $t('my.share') }}</div>
				</div>
				<div class="btn-g">
					<div class="btn">{{ $t('my.recharge') }}</div>
					<div class="btn" @click="$router.push({ path: '/RechargeRecord' })">{{ $t('my.recharge_record') }}</div>
					<div class="btnout" @click="login_out()">{{ $t('my.logout') }}</div>
				</div>
			</div>
			
			<div class="prosearch">
				<div class="catselect">
					<div class="inputfield" style="position: relative;">
						<van-field v-model="pronum" class="input" readonly :placeholder="$t('my.all')" @click="active_num ? active_num = false : active_num = true"></van-field>
						<van-popup v-model="active_num" position="top">
							<div class="wrapper">
								<div class="item">
									<ul>
										<li @click="selpronum(0)">{{ $t('my.all') }}</li>
										<li @click="selpronum('pending')">{{ $t("my.pending") }}</li>
										<li @click="selpronum('on_the_way')">{{ $t("my.on_the_way") }}</li>
									</ul>
								</div>
								
							</div>
						</van-popup>
					</div>
					<van-button class="login-btn" type="primary" size="normal" @click="selectpro()">{{ $t('my.piliang') }}</van-button>
				</div>
				
				<div class="pagebox">
					<van-pagination v-model="page" :total-items="totalnum" :show-page-size="5" prev-text="&lt;" next-text="&gt;" force-ellipses @change="getProductList" />
				</div>
			</div>
			<div class="prolisttit">
				<div style="width: 10%;">
					<van-checkbox v-model="allcheck" shape="square" icon-size="14px">{{ $t('my.tabstr1') }}</van-checkbox>
				</div>
				<div style="flex: 1;">{{ $t('my.tabstr2') }}</div>
				<div style="width: 15%;">{{ $t('my.tabstr3') }}</div>
				<div style="width: 8%;">{{ $t('my.tabstr4') }}</div>
				<div style="width: 8%;">{{ $t('my.tabstr5') }}</div>
				<div style="width: 10%;">{{ $t('my.tabstr6') }}</div>
				<div style="width: 15%;">{{ $t('my.tabstr7') }}</div>
				<div style="width: 10%;">{{ $t('my.tabstr8') }}</div>
			</div>
			<div style="position: relative;">
				<div class="productlist" v-for="(item,index) in prolist" :key="index">
					<div style="width: 10%;display: flex;align-items: center;" class="chk">
						<van-checkbox v-model="item.isChk" shape="square" icon-size="14px"> </van-checkbox>
						<span @click="showorder(item.id)">{{ $t('my.tabstr9') }}</span>
					</div>
					<div style="flex: 1;" class="proname">
						<div v-for="(pro,ind) in item.orderinfo" :key="ind">
							<p>{{ pro.name }}</p>
							<div style="display: flex;align-items: center;">
								<img :src="pro.pro_img" style="width: 100px; height: 100px;margin-right: 20px;" />
								<p>x{{ pro.quantity }} ${{ pro.price }}</p>
							</div>
						</div>
					</div>
					<div style="width: 15%;" class="proinfo">{{ item.express?item.express.express_code:'' }}</div>
					<div style="width: 8%;" class="price">{{ item.product_storehouse_total }}</div>
					<div style="width: 8%;" class="price">{{ item.lirun }}</div>
					<div style="width: 10%;" class="delivery"><span>{{ $t("my." + item.delivery_status) }}</span></div>
					<div style="width: 15%;" class="price">{{ item.code }}</div>
					<div style="width: 10%;" class="price">{{ item.created_at }}</div>
				</div>
				
				<van-popup v-model="order_popup" center class="order_show" position="top">
					<div class="order_box">
						<div class="tit">
							<span>{{ $t('my.ordstr1') }}</span>
							<span @click="order_popup=false">X</span>
						</div>
						<ul class="info">
							<li>
								<div>{{ $t('my.ordstr2') }}</div>
								<div>{{ orderdata.code }}</div>
							</li>
							<li>
								<div>{{ $t('my.ordstr3') }}</div>
								<div>{{ orderdata.created_at }}</div>
							</li>
							<li>
								<div>{{ $t('my.ordstr4') }}</div>
								<div>{{ orderdata.address.name }}</div>
							</li>
							<li>
								<div>{{ $t('my.ordstr5') }}</div>
								<div>{{ orderdata.address.email }}</div>
							</li>
							<li>
								<div>{{ $t('my.ordstr6') }}</div>
								<div>{{ orderdata.address.phone }}</div>
							</li>
							<li>
								<div>{{ $t('my.ordstr7') }}</div>
								<div>{{ orderdata.address.country }}-{{ orderdata.address.city }}-{{ orderdata.address.state }}-{{ orderdata.address.address }}</div>
							</li>
							<li>
								<div>{{ $t('my.ordstr8') }}</div>
								<div>{{ orderdata.product_storehouse_total }}</div>
							</li>
							<li>
								<div>{{ $t('my.ordstr9') }}</div>
								<div>{{ orderdata.lirun }}</div>
							</li>
						</ul>
						<div class="pro_info">
							<ul class="protitle">
								<li>{{ $t('my.ordstr10') }}</li>
								<li>{{ $t('my.ordstr11') }}</li>
								<li>{{ $t('my.ordstr12') }}</li>
								<li>{{ $t('my.ordstr13') }}</li>
							</ul>
							<ul class="pro_item" v-for="(item,ind) in orderdata.detail" :key="ind">
								<li>{{ item.name }}</li>
								<li><img :src="item.pro_img" style="width: 100px; height: 100px;margin-right: 20px;" /></li>
								<li>{{ item.quantity }} </li>
								<li>${{ item.price }}</li>
							</ul>
						</div>
					</div>
				</van-popup>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	data() {
		return {
			userInfo:{
				name:'',
				phone:'',
				email:'',
				avatar:'',
				money:0.00
			},
			uid:0,
			menu_top: 40,
			isLoading: false,
			prolist:[],
			pagelist:[],
			active_num:false,
			pronum:'全部',
			isChecked:false,
			de_status:'',
			order_popup:false,
			orderdata:{
				code:'',
				created_at:'',
				product_storehouse_total:'',
				lirun:'',
				address:{
					name:'',
					email:'',
					phone:'',
					country:'',
					city:'',
					state:'',
					address:'',
				},
				detail:{

				}
			},
			page:1,
			totalnum:0
		};
	},
	computed:{
		allcheck:{
			get(){
				//取值
				//every方法，数组中每一项都满足一个条件返回true
				return this.prolist.length && this.prolist.every(item=>item.isChk)
			},
			set(newValue){
				//设置值
				this.prolist.map(item=>item.isChk=newValue)
			}
		},
	},
	methods: {
		login_out() {
			localStorage.clear()
			this.$router.push({ path: '/Home' });
		},
		selpronum(item){
			if(item==0){
				this.pronum = '全部';
				this.de_status='';
			}
			else{
				this.pronum = this.$t("my."+item);
				this.de_status=item;
			}
			this.getOrder();
			this.active_num = false;
		},
		selectpro(){
			let ids='0';
			this.prolist.forEach(item_p => {
				if(item_p.isChk){
					ids += ',' + item_p.id;
				}
			});
			this.$http({
				method: 'get',
				url: 'orderfahuo',
				data:{
					ids:ids
				}
			}).then(res => {
				console.log(res);
				this.getOrder();
			})
		},
		getUserInfo() {
			this.$http({
				method: 'get',
				url: 'shopinfo',
				data:{
					id:this.uid
				}
			}).then(res => {
				if(res.code==1){
					if(res.data.logo){
						localStorage.setItem("selleravatar", res.data.logo);
						this.userInfo.avatar = res.data.logo;
					}
					this.userInfo.money = res.data.money;
				}
			})
		},
		getOrder(){
			this.$http({
				method: 'get',
				url: 'shoporder',
				data:{
					type:this.de_status,
					page:this.page,
				}
			}).then(res => {
				this.prolist=res.data;
				this.totalnum = res.data.count;
			})
		},
		showorder(id){
			this.order_popup=true;
			this.$http({
				method: 'get',
				url: 'shopordershow',
				data:{id:id}
			}).then(res => {
				console.log(res);
				this.orderdata=res.data;
			})
		}
	},
	created() {
		if (localStorage.getItem('access_token')) {
			this.uid = localStorage.getItem("sellerid");
			this.userInfo.email = localStorage.getItem("selleremail");
			this.userInfo.phone = localStorage.getItem("sellerphone");
			this.userInfo.avatar = localStorage.getItem("selleravatar");
			this.userInfo.name = localStorage.getItem("sellername");
			this.getUserInfo();
			this.getOrder();
		} else {
			this.$router.push({path:'/Login'})
		}
	}
};
</script>

<style lang='less' scoped>
.content_box {
	width: 100%;
	max-width: 1200px;
	margin: 0px auto 0;
	padding-top: 30px;
	position: relative;
	.usheader{
		background-color: #000102;
		padding: 50px 0;
		margin-top: 100px;
		border-radius: 10px;
			justify-content: space-between;
		display: flex;
		position: relative;
		.avatar{
			position: absolute;
			top: -80px;
			width: 150px;
			height: 150px;
			border-radius: 50%;
			overflow: hidden;
			margin-left: 60px;
			img{
				width: 100%;
				height: 100%;
				border-radius: 75px;
			}
		}
		.user-mate{
			flex: 3;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			color: #fff;
			margin-left: 20%;
			font-size: 18px;
			.btn{
				background: #f5af02;
				padding: 5px 10px;
				border-radius: 6px;
				margin-left: 20px;
			}
		}
		.btn-g{
			display: flex;
			.btn{
				padding: 5px 15px;
				font-size: 16px;
				background: #c9151e;
				color: #fff;
				margin-right: 20px;
				border-radius: 4px;
				cursor: pointer;
			}
			.btnout{
				padding: 5px 15px;
				font-size: 16px;
				background: #4b8538;
				color: #fff;
				margin-right: 20px;
				border-radius: 4px;
				cursor: pointer;
			}
		}
	}
	.prosearch{
		display: flex;
		padding: 15px 0;
		.catselect{
			width: 100%;
			display: flex;
			justify-content:flex-start;
			.inputfield{
				border:solid 1px #ccc;
				border-radius: 4px;
			}
		}
	}
}
.item{
	padding: 10px 0;
}
.item li{
	line-height: 24px;
	margin: 10px 20px;
	cursor: pointer;
}

.pagebox{
	display: flex;
	//padding-top: 10px;
	justify-content: end;
	span a{
		padding: 0 6px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		display: block;
		margin: 0 4px;
		background: #ececec;
		color: #000;
	}
	span.on a{
		background: #c9151e;
		color: #fff;
	}
}
::v-deep .van-pagination__item{
	color: #000;
	height: 36px;
}
::v-deep .van-pagination__item--active{
	background: #c9151e;
	color: #fff;
}

::v-deep .van-checkbox__label{
	color:#fff;
	font-size: 13px;
}
::v-deep .van-button--normal{
	font-size: 14px;
	width: 88px;
	height: 35px;
	padding: 0 10px;
	border:none;
	background: #c9151e;
}
::v-deep .van-cell{
	padding: 5px 16px;
}
::v-deep .van-popup {
	position: absolute;
	top: 46px;
	background: #fff;
	max-height: 274px;
}
.order_show{
	position: fixed;
	top:20%;
	max-width: 1200px;
	margin: 0 auto;
	left:calc((100% - 1200px)/2);
	min-height: 450px;
	.order_box{
		padding: 20px;
		width: 100%;
		.tit{
			display: flex;
			justify-content: space-between;
		}
		.info{
			padding: 20px;
			display: flex;
			justify-content: space-between;
			width: 100%;
			flex-wrap: wrap;
			li{
				width: 40%;
				display: flex;
				justify-content: space-between;
				line-height: 40px;
				div:first-child{
					font-weight: bold;
				}
			}
		}
		.pro_info{
			width: 60%;
			.protitle{
				display: flex;
				background: #000;
				color: #fff;
				padding: 10px;
				li{
					width: 25%;
					font-size: 14px;
					font-weight: bold;
				}
			}
			.pro_item{
				display: flex;
				align-items: center;
				border-bottom: solid 1px #ccc;
				li{
					width: 25%;
					font-size: 14px;
					text-wrap: wrap;
					padding: 10px;
				}
			}
		}
	}
}
.prolisttit{
    display: flex;
    color: #fff;
    background: #2b2b2b;
    padding: 5px 10px;
}
.productlist{
	display: flex;
	padding: 10px;
	line-height: 30px;
	border-bottom: solid 1px #ccc;
	margin-bottom: 15px;
	div{
		padding: 0 5px;
		p{
			margin: 0;
			line-height: 22px;
			font-size: 12px;
		}
	}
	.chk{
		span{
			margin: 0 5px;
			display: block;
			padding: 2px 8px;
			background: #2b2b2b;
			color: #fff;
			border-radius: 4px;
			font-size: 14px;
		}
	}
	.delivery{
		span{
			background-color: rgb(76, 133, 56);
			color: rgb(255, 255, 255);
			text-align: center;
			width: 80px;
			padding: 4px 10px;
			border-radius: 3px;
		}
		
	}
}
</style>
